
import React, { useState } from 'react';
import ConfirmDialog from '../../../components/dialog/ConfirmDialog';
import './removeButton.css';
import deleteIcon from './delete-icon.png';

const RemoveButton = ({ onConfirm }) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const handleClick = () => {
        setShowConfirmDialog(true); // Exibe o diálogo de confirmação ao clicar no botão
    };

    const handleConfirm = () => {
        setShowConfirmDialog(false); // Fecha o diálogo
        onConfirm();
    };

    const handleCancel = () => {
        setShowConfirmDialog(false); // Fecha o diálogo sem confirmar
    };

    return (
        <>
            <button onClick={handleClick} className="delete-button">
                <img src={deleteIcon} alt="Delete" />
            </button>
            {showConfirmDialog && (
                <ConfirmDialog
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </>
    );
};

export default RemoveButton;
