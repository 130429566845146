import React, { useState, useEffect } from 'react';
import HeaderAdmin from "../../adminComponents/headerAdmin/header_admin";
import SearchBox from "../../../school/schoolCommons/searchBox/searchBox";
import AddSchoolButton from "../../adminComponents/addSchoolButton/addScholButton";
import SchoolCardList from "../../adminComponents/adminSchoolCardList/adminSchoolCardList";
import Loader from "../../adminController/adminBookCardLoader/loader";
import SchoolFormModal from "../../adminComponents/schoolForm/schoolForm";
import '../escolaPage/escolapage.css';

function EscolaPage() {
  const [schoolData, setSchoolData] = useState([]); // Agora diretamente um array
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const baseUrl = process.env.REACT_APP_API_BASE_URL;

        setLoading(true);

        const response = await fetch(`${baseUrl}/schools/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Dados recebidos do backend:', data);

          // Força que data seja sempre um array
          const normalizedData = Array.isArray(data) ? data : [data];
          setSchoolData(normalizedData);
        } else {
          console.error("Erro ao buscar os dados das escolas");
        }
      } catch (error) {
        console.error("Erro de conexão", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSchoolData();
  }, []);

  const filteredItems = schoolData.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="div-main-escola-page">
      <HeaderAdmin />

      <div className="search-container-escola">
        <SearchBox
          placeholder="Pesquise por uma escola"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <AddSchoolButton className="add-school-button" />
      </div>

      {filteredItems.length > 0 ? (
        <SchoolCardList schoolData={filteredItems} />
      ) : (
        !loading && <p style={{ }}>Nenhuma escola encontrada.</p>
      )}

      {loading && <Loader />}

      <SchoolFormModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
}

export default EscolaPage;
