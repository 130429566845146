import React from "react";
import './schoolAjudaPage.css';
import Header_school from "../../schoolComponents/headerSchool/headerSchool";


function AjudaSchoolPage() {

    return (
    <div className='help-school'>
     <Header_school /> 
      <div className='help-container-school'>

        <div className='help-title-school'>
          <h1>Bem-vindo!</h1>
        </div>

        <div className='help-texts-school'>
          <div className="help-text-school">
            <h1>Turmas</h1>
            <p>
              Organize e administre as turmas de alunos, gerenciando informações como lista de integrantes.
            </p>
          </div>
       

       
          <div className='help-text-school'>
            <h1>Alunos</h1>
            <p>Gerencie o cadastro e as informações dos alunos associados ao sistema.</p>
          </div>

          <div className='help-text-school'>
            <h1>Catálogo</h1>
            <p>Visualize e edite o catálogo de livros disponibilizados na plataforma.</p>
          </div>

        </div>
        
        <p className='help-doc-school'>
          Precisa de uma mãozinha? Consulte nossa{" "}
          <a href="/Inserção_de_Livros_VIR.pdf" download className='help-doc-download'>
            documentação
          </a>{" "}
          e tire suas dúvidas.
        </p>
        <div className='help-downloads-school'>
          <a className='help-download-btn-school' download href='/Inserção_de_Livros_VIR.pdf'>Download PDF catálogo</a>
          <a className='help-download-btn-school' download href='/path/to/your/pdf/documentacao.pdf'>Download PDF Relatórios</a>
        </div>
      </div>
    </div>
  );
}

export default AjudaSchoolPage;

