import RelatorioPage from "../adminPages/relatoriosPage/relatorio.page";

function Relatorio() {
    return (
      <div>
        <RelatorioPage />
      </div>
    );
  }
  
  export default Relatorio;