import React, { useState, useEffect } from "react";
import "./catalogo.page.css";
import Header_admin from "../../adminComponents/headerAdmin/header_admin.jsx";
import Bookcard_list from "../../adminComponents/adminBookCardList/adminBookCardList.jsx";
import Loader from "../../adminController/adminBookCardLoader/loader.jsx";
import AddBookButton from "../../adminComponents/uploadButton/uploadButton.jsx";
import BookFormModal from "../../adminComponents/bookForm/bookForm.jsx";
import SearchBox from "../../../school/schoolCommons/searchBox/searchBox";

const CatalogoPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [books, setBooks] = useState({ bookData: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const baseUrl = process.env.REACT_APP_API_BASE_URL;

        const response = await fetch(`${baseUrl}/books/documentation/get_books`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Erro ao buscar os dados da biblioteca.");
        }

        const data = await response.json();
        setBooks({ bookData: data });
        console.log(data);
      } catch (error) {
        console.error("Erro ao buscar livros da biblioteca:", error);
      }
    };

    fetchData();
  }, []);

  const filteredItems = Array.isArray(books.bookData)
    ? books.bookData.filter(
        (item) =>
          item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.author.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async (bookId) => {
    setBooks(books.filter((book) => book.id !== bookId));
    const url = `https://virback.totalclan.com.br/books/${bookId}`;
    const token = localStorage.getItem("access_token");

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        console.log(
          `Livro com ID ${bookId} removido com sucesso da biblioteca do usuário.`
        );
      } else {
        console.error(
          `Erro ao remover o livro com ID ${bookId} da biblioteca. Código de status: ${response.status}`
        );
      }
    } catch (error) {
      console.error(
        `Erro ao conectar com o servidor para remover o livro com ID ${bookId}:`,
        error
      );
    }
  };

  const handleEdit = async (bookId) => {
    const url = `https://virback.totalclan.com.br/books/get_book_user/${bookId}`;
    const token = localStorage.getItem("access_token");

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        console.log(
          `Dados do livro com ID ${bookId} carregados para edição com sucesso.`
        );
      } else {
        console.error(
          `Erro ao carregar os dados do livro com ID ${bookId} para edição. Código de status: ${response.status}`
        );
      }
    } catch (error) {
      console.error(
        `Erro ao conectar com o servidor para editar o livro com ID ${bookId}:`,
        error
      );
    }
  };

  return (
    <div className="div-main-catalogo-page">
      <Header_admin />
     
        <div className="search-catalog-content">
          <SearchBox
            placeholder="Pesquise por um livro ou autor"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="add-book-catalog-button">
            <AddBookButton onClick={() => setIsModalOpen(true)} />
          </div>
        </div>
        {filteredItems.length > 0 ? (
          filteredItems.map((item) => (
            <Bookcard_list
              key={item.id} // Adicionado para identificar cada item
              bookData={books}
              onDelete={(id) => handleDelete(id)}
              onEdit={(id) => handleEdit(id)}
            />
          ))
        ) : (
          <p style={{ textAlign: "center" }}>Nenhum livro encontrado.</p>
        )}
        {loading && <Loader />}
      
      <BookFormModal isOpen={isModalOpen} onClose={handleClose} />
    </div>
  );
};

export default CatalogoPage;
