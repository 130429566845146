import React from "react";
import HeaderForm from "../../../school/schoolComponents/headerForm/headerForm";
import FooterForm from "../../../school/schoolComponents/footerForm/footerForm";
import CommonInput from "../../../school/schoolCommons/commonInput/commonInput";
import "./studentForm.css";

const StudentFormModal = ({ isOpen, setIsOpen }) => {
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    class: "",
    password: "",
  });

  const [errors, setErrors] = React.useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const validateFields = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Nome é obrigatório.";
    if (!formData.email) newErrors.email = "Email é obrigatório.";
    if (!formData.class) newErrors.class = "Turma é obrigatória.";
    if (!formData.password) newErrors.password = "Senha é obrigatória.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      alert("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    try {
      const token = localStorage.getItem("access_token");
      const baseUrl = process.env.REACT_APP_API_BASE_URL;

      const response = await fetch(`${baseUrl}/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          turma: formData.class,
          password: formData.password,
          role: "reader", // Adiciona a role padrão
        }),
      });

      if (response.ok) {
        alert("Aluno adicionado com sucesso!");
        setFormData({
          name: "",
          email: "",
          class: "",
          password: "",
        });
        setIsOpen(false);
      } else {
        const errorResponse = await response.json();
        console.error("Erro ao adicionar o aluno:", errorResponse);
        alert(`Erro: ${errorResponse.message || "Erro desconhecido"}`);
      }
    } catch (error) {
      console.error("Erro ao adicionar o aluno:", error);
      alert("Erro de conexão. Verifique a rede e tente novamente.");
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <HeaderForm title="Adicionar Aluno" onClose={() => setIsOpen(false)} />
        <div className="circle-and-form">
          <div className="form-content">
            <form onSubmit={handleSubmit}>
              <CommonInput
                label="Nome"
                name="name"
                placeholder="Nome do aluno"
                value={formData.name}
                onChange={handleChange}
                error={errors.name}
              />
              <CommonInput
                label="Email"
                name="email"
                placeholder="Email do aluno"
                value={formData.email}
                onChange={handleChange}
                error={errors.email}
              />
              <CommonInput
                label="Turma"
                name="class"
                placeholder="Turma do aluno"
                value={formData.class}
                onChange={handleChange}
                error={errors.class}
              />
              <CommonInput
                label="Senha"
                name="password"
                type="password"
                placeholder="Insira a senha do aluno"
                value={formData.password}
                onChange={handleChange}
                error={errors.password}
              />
            </form>
          </div>
        </div>
        <FooterForm onCancel={handleCancel} onConfirm={handleSubmit} />
      </div>
    </div>
  );
};

export default StudentFormModal;
