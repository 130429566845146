import React, { useState } from "react";
import SchoolFormModal from "../schoolForm/schoolForm";
import './addSchoolButton.css'; 

const AddSchoolButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openForm = () => {
    setIsOpen(true);
  };

  const closeForm = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <button className="add-school-button" onClick={openForm}>
        Adicionar Escola
      </button>

      <SchoolFormModal isOpen={isOpen} setIsOpen={closeForm} />
    </div>
  );
};

export default AddSchoolButton;
