import React, { useState, useEffect } from 'react';
import './schoolCatalogoPage.css';
import Header_school from '../../schoolComponents/headerSchool/headerSchool';
import SchoolCatalogList from '../../schoolComponents/schoolCatalogList/schoolCatalogList';
import SearchBox from '../../schoolCommons/searchBox/searchBox';

const CatalogoSchoolPage = () => {
  const [libraryData, setLibraryData] = useState({ listItems: [] });
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserIdAndData = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('access_token');
      const baseUrl = process.env.REACT_APP_API_BASE_URL;

      const booksResponse = await fetch(`${baseUrl}/books/get_library_books`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (booksResponse.ok) {
        const booksData = await booksResponse.json();
        setLibraryData({ listItems: booksData });
      } else {
        console.error('Erro ao buscar os dados da biblioteca');
        setLibraryData({ listItems: [] });
      }
    } catch (error) {
      console.error('Erro de conexão', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserIdAndData();
  }, []);

  const filteredItems = libraryData.listItems.filter(
    (item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.author.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="catalog-main">
      <Header_school />
      <div className="search-component-catalog">
        <SearchBox
          placeholder="Pesquise por título ou autor"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Lógica do input
        />
      </div>
      {isLoading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <div className="loading-circle"></div>
          <p>Carregando livros...</p>
        </div>
      ) : filteredItems.length > 0 ? (
        filteredItems.map((item) => (
          <SchoolCatalogList
            key={item.id}
            id={item.id}
            title={item.title}
            author={item.author}
            image={item.cover}
            totalPages={item.totalPages}
          />
        ))
      ) : (
        <p style={{ textAlign: 'center' }}>Nenhum livro encontrado.</p>
      )}
    </div>
  );
};

export default CatalogoSchoolPage;
