import React from "react";
import "./commonInput.css";

const CommonInput = ({ label, name, placeholder, value, onChange, type = "text", error }) => (
  <div className="common-input">
    <label className="input-label">{label}</label>
    <input
      className="input-field"
      type={type} // Suporte para diferentes tipos de input
      name={name} // Propaga o nome para identificação
      placeholder={placeholder} // Exibe o placeholder
      value={value || ""} // Controla o valor do input
      onChange={onChange} // Propaga o evento para o componente pai
    />
    {error && <small className="error-text">{error}</small>} {/* Exibe mensagens de erro, se houver */}
  </div>
);

export default CommonInput;