import React from "react";
import "./bookCard.css";
import EditButton from "../editButton/editButton";
import RemoveButton from "../removeButton/removeButton";

const Bookcard = ({image,name,autor,editHandle,deleteHandle }) => {
  return (
    <div className="book-card">
      <div className="book-card-image">
        { <img src={image} alt="book" /> }
      </div>
      <div className="book-card-info">
        <h1 className="book-card-name">{name}</h1>
        <h2 className="book-card-autor">{autor}</h2>
        <div className="book-card-buttons">
        <RemoveButton onConfirm={deleteHandle}/>
        <EditButton onEdit={editHandle}/>
        </div>
      </div>
    </div>
  );
};

export default Bookcard;