import CatalogoPage from "../adminPages/catalogoPage/catalogo.page";

function Catalogo() {
    return (
      <div >
       <CatalogoPage/>
      </div>
    );
  }
  
  export default Catalogo;