import React from "react";
import './searchBox.css';
import SearchIcon from './search-icon.png';

const SearchBox = ({ placeholder, value, onChange }) => (
  <div className="search-box">
    <input
      className="search-input"
      placeholder={placeholder}
      value={value}
      onChange={onChange} // Atualiza o estado do componente pai
    />
    <img className="search-icon" src={SearchIcon} alt="Search Icon" />
  </div>
);

export default SearchBox;