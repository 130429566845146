import AlunoPage from "../schoolPages/schoolAlunosPage/alunoPage";

function Alunos() {
    return (
      <div>
       <AlunoPage />
      </div>
    );
  }
  
  export default Alunos;
