import React from "react";
import Bookcard from "../../adminCommons/adminBookCard/bookCard";

const Bookcard_list = ({ bookData, editHandle, deleteHandle }) => {
    return (
        <div className='bookcard_list'>
            {bookData.map((book, index) => {
                return (
                    <Bookcard
                        key={index}
                        image={book.image}
                        name={book.name}
                        autor={book.autor}
                        editHandle={editHandle}
                        deleteHandle={deleteHandle}
                    />
                );
            })}
        </div>
    );
};

export default Bookcard_list;