
import React, { useState } from 'react';
import './editButton.css';
import editIcon from './IconEdit.png';

const EditButton = ({ onEdit }) => {
    const [showEditForm, setShowEditForm] = useState(false);

    const handleClick = () => {
        setShowEditForm(true); // Exibe o formulário de edição ao clicar no botão
    };

    const handleClose = () => {
        setShowEditForm(false); // Fecha o formulário de edição
    };

    return (
        <>
            <button onClick={handleClick} className="edit-button">
                <img src={editIcon} alt="Edit" className='icon-edit' />
            </button>
            {showEditForm && (
                <div className="edit-form-modal">
                    <div className="edit-form-content">
                        <h2>Editar Livro</h2>
                        {/*inserir o formulário de edição dos campos do livro */}
                        <button onClick={handleClose}>Fechar</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default EditButton;
