import React, { useState } from "react";
import List from "../../schoolCommons/generic-list-school/genericListSchool";
import CatalogSinopsis from "../catalogSinopsis/catalogSinopsis";
import './schoolCatalogList.css';

const SchoolCatalogList = ({ id, title, image, author, totalPages, category }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [bookData, setBookData] = useState(null);

  const fetchBookData = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const baseUrl = process.env.REACT_APP_API_BASE_URL;

      const response = await fetch(`${baseUrl}/books/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar os dados do livro.');
      }

      const data = await response.json();
      setBookData(data);
      setIsPopupOpen(true); // Abre o popup após carregar os dados
    } catch (error) {
      console.error('Erro ao buscar os dados do livro:', error);
    }
  };

  const handleOpenPopup = () => {
    fetchBookData();
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setBookData(null); // Limpa os dados ao fechar
  };

  return (
   <div>
        <List title={title} image={image}>
        <div className="catalog-item-info">
          <div className="catalog-item-description">
            <p className="catalog-item-author">{author}</p>
            <p className="catalog-item-total-pages">{totalPages} páginas</p>
          </div>

          <button
            className="catalog-item-sinopsis-button"
            onClick={handleOpenPopup}
          >
            Sinopse do Livro
          </button>
        </div>
      </List>

      
      {bookData && (
       <CatalogSinopsis 
        
          isOpen={isPopupOpen}
          onClose={handleClosePopup}
          bookData={bookData}
       />
        
      )}
  </div>


  );
};

export default SchoolCatalogList;
