import React, { useState, useEffect } from "react";
import SearchBox from "../../schoolCommons/searchBox/searchBox";
import StudentList from "../../schoolComponents/studentList/studentList";
import StudentFormModal from "../../schoolComponents/studentForm/studentForm";
import "./alunopage.css";
import Header_school from "../../schoolComponents/headerSchool/headerSchool";

function AlunoPage() {

  const [students, setStudentData] = useState({ listItems: [] });

  const fetchStudents = async () => {
    try {
      const token = localStorage.getItem('access_token'); // Access token obtido de uma fonte segura
      const baseUrl = process.env.REACT_APP_API_BASE_URL;

      // Fazendo a requisição para buscar todos os livros do backend
      const studentsResponse = await fetch(`${baseUrl}/schools/get_students`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (studentsResponse.ok) {
        const students = await studentsResponse.json();
        console.log(students);
        setStudentData({ listItems: students });
      } else {
        console.error("Erro ao buscar os dados da biblioteca");
        setStudentData({ listItems: [] });
      }
    } catch (error) {
      console.error("Erro de conexão", error);
    }
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  return (
    <div className="div-main">
      <Header_school />
         <div className="container-search">
        <SearchBox placeholder="Pesquisar alunos" />
        <button className="button-adicionarAluno" onClick={openModal}>
          Adicionar Aluno
        </button>
      </div>
      <div className="container-list">
        <StudentList students={students.listItems} />
      </div>
      
      <StudentFormModal isOpen={isOpen} setIsOpen={closeModal} />
    </div>
  );
}

export default AlunoPage;
