import AjudaSchoolPage from "../schoolPages/schoolAjudaPage/schoolAjudaPage";
import React from "react";



function AjudaSchool(){
    return (
        <div>
            <AjudaSchoolPage />
        </div>
    );
}


export default AjudaSchool;