import React, { useState, useEffect } from "react";
import HeaderForm from "../headerForm/headerForm"; // Cabeçalho reutilizável
import FooterForm from "../footerForm/footerForm"; // Rodapé reutilizável
import CommonInput from "../../adminCommons/commonInput/commonInput"; // Campo de input reutilizável
import "./bookForm.css";

const BookFormModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    id: "",
    author: "",
    chapter_number: "",
    cover: null,
    description: "",
    page_number: "",
    title: "",
    total_pages: "",
    contentPdf: null, // Campo para PDF do livro
    gif_url: [], // Array para armazenar GIFs
  });

  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // Indicador de carregamento

  // Carrega as categorias ao montar o componente
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${baseUrl}/categories/`);
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error("Erro ao carregar categorias:", error);
      }
    };
    fetchCategories();
  }, []);

  // Atualiza os dados do formulário
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // Manipula campos de arquivo
    if (name === "cover" || name === "contentPdf") {
      const file = files[0];
      if (name === "cover" && file && !["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
        setErrors((prev) => ({ ...prev, [name]: "A capa deve ser um arquivo JPG, JPEG ou PNG." }));
      } else if (name === "contentPdf" && file && file.type !== "application/pdf") {
        setErrors((prev) => ({ ...prev, [name]: "O conteúdo deve ser um arquivo PDF." }));
      } else {
        setErrors((prev) => ({ ...prev, [name]: "" }));
        setFormData((prevData) => ({ ...prevData, [name]: file }));
      }
    } else if (name === "gif_url") {
      const gifs = Array.from(files);
      const invalidFiles = gifs.filter(
        (file) => file.type !== "image/gif"
      );
      if (invalidFiles.length > 0) {
        setErrors((prev) => ({
          ...prev,
          gif_url: "Todos os arquivos devem ser do tipo GIF.",
        }));
      } else {
        setErrors((prev) => ({ ...prev, gif_url: "" }));
        setFormData((prevData) => ({
          ...prevData,
          gif_url: [...prevData.gif_url, ...gifs],
        }));
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  // Valida os campos obrigatórios
  const validateForm = () => {
    const requiredFields = ["title", "author", "description", "category_name", "cover", "contentPdf"];
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = "Este campo é obrigatório.";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Envia o formulário
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    const formDataToSend = new FormData();
    const bookData = {
      author: formData.author,
      category_name: formData.category_name,
      chapter_number: formData.chapter_number,
      description: formData.description,
      id: formData.id,
      page_number: formData.page_number,
      published_at: formData.published_at,
      title: formData.title,
      total_pages: formData.total_pages,
    };

    formDataToSend.append("book", JSON.stringify(bookData));
    formDataToSend.append("cover", formData.cover);
    if (formData.contentPdf) {
      formDataToSend.append("contentPdf", formData.contentPdf);
    }
    formData.gif_url.forEach((gif) => {
      formDataToSend.append("gif_url[]", gif);
    });

    try {
      const token = localStorage.getItem("access_token");
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${baseUrl}/books/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataToSend,
      });

      if (response.ok) {
        alert("Livro enviado com sucesso!");
        onClose(); // Fecha o modal após o envio bem-sucedido
        setFormData({
          id: "",
          author: "",
          chapter_number: "",
          cover: null,
          description: "",
          page_number: "",
          title: "",
          total_pages: "",
          contentPdf: null,
          gif_url: [],
        });
      } else {
        const errorResponse = await response.json();
        alert(`Erro ao enviar: ${errorResponse.message || "Erro desconhecido."}`);
      }
    } catch (error) {
      console.error("Erro ao enviar o formulário:", error);
      alert("Erro de conexão. Verifique sua rede.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay-book" onClick={(e) => e.target.classList.contains("modal-overlay") && onClose()}>
     <div className="modal-content-book">
        <HeaderForm title="Adicionar Livro" onClose={onClose} />
        <div className="form-content-book">
        <form className="form-container-book" onSubmit={handleSubmit}>
          <CommonInput
            label="Título"
            name="title"
            value={formData.title}
            onChange={handleChange}
            error={errors.title}
            required
          />
          <CommonInput
            label="Autor"
            name="author"
            value={formData.author}
            onChange={handleChange}
            error={errors.author}
            required
          />
          <CommonInput
            label="Descrição"
            name="description"
            value={formData.description}
            onChange={handleChange}
            error={errors.description}
            required
          />
          <div>
            <label>Categoria:</label>
            <select
              name="category_name"
              value={formData.category_name}
              onChange={handleChange}
              style={{ borderColor: errors.category_name ? "red" : "initial" }}
            >
              <option value="">Selecione uma categoria</option>
              {categories.map((category) => (
                <option key={category.id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </select>
            {errors.category_name && <small>{errors.category_name}</small>}
          </div>
          <div>
            <label>Capa (JPG/PNG/JPEG):</label>
            <input type="file" name="cover" onChange={handleChange} />
            {errors.cover && <small>{errors.cover}</small>}
          </div>
          <div>
            <label>Conteúdo (PDF):</label>
            <input type="file" name="contentPdf" accept="application/pdf" onChange={handleChange} />
            {errors.contentPdf && <small>{errors.contentPdf}</small>}
          </div>
          <div>
            <label>Animações (GIFs):</label>
            <input
              type="file"
              name="gif_url"
              multiple
              accept="image/gif"
              onChange={handleChange}
            />
            {errors.gif_url && <small>{errors.gif_url}</small>}
          </div>
        </form>
        <FooterForm
            onCancel={onClose}
            onConfirm={handleSubmit}
            isLoading={isSubmitting}
            confirmText="Confirmar"
          />
        </div>
      </div>
    </div>
  );
};

export default BookFormModal;
