
import React from 'react';
import './ConfirmDialog.css';

const ConfirmDialog = ({ onConfirm, onCancel }) => {
    return (
        <div className="confirm-dialog-overlay">
            <div className="confirm-dialog">
                <p>Tem certeza de que deseja excluir este item?</p>
                <div className="dialog-buttons">
                    <button onClick={onConfirm} className="confirm-button">Sim</button>
                    <button onClick={onCancel} className="cancel-button">Não</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDialog;
