import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


import './header.css'

const Header_school = () => {
    const [selected, setSelected] = useState(null); // Inicializa como null
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      const pathMap = {
          '/ajudaescola': 'ajudaEscola',
          '/alunos': 'alunos',
          '/catalogoescola': 'catalogoEscola',
      };
      setSelected(pathMap[location.pathname.toLowerCase()] || null);
  }, [location]);

      const handleClick = (button, route) => {
        if (selected !== button) { 
            setSelected(button); // Atualiza o estado primeiro
            setTimeout(() => navigate(route), 10); // Navega após uma pequena espera para garantir a animação
        }
    };
return (
    <nav className="navbar">

    <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25" cy="25" r="25" fill="white" />
    </svg>

      <div className={`nav-item ${selected === 'catalogoEscola' ? 'selected' : ''}`} onClick={() => handleClick('catalogoEscola', '/catalogoescola')}>
        Catálogo
      </div>
      <div className={`nav-item ${selected === 'alunos' ? 'selected' : ''}`} onClick={() => handleClick('alunos', '/alunos')}>
        Alunos
      </div>
      <div className={`nav-item ${selected === 'ajudaEscola' ? 'selected' : ''}`} onClick={() => handleClick('ajudaEscola', '/ajudaescola')}>
        Ajuda
      </div>
    
      <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25" cy="25" r="25" fill="white" />
    </svg>
      
    </nav>
  );

};

export default Header_school;
