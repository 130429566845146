import SchoolCard from "../../adminCommons/adminSchoolCard/schoolCard";
import "./adminSchoolCard.css";
const SchoolCardList = ({ schoolData }) => {
  return (
    <div className="schoolcard-list">
      {schoolData.map((school, index) => (
        <SchoolCard
          key={index}
          name={school.name}
          editHandle={() => console.log(`Editar ${school.name}`)}
          deleteHandle={() => console.log(`Deletar ${school.name}`)}
        />
      ))}
    </div>
  );
};

export default SchoolCardList;