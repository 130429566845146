import React from "react";
import HeaderForm from "../headerForm/headerForm";
import FooterForm from "../footerForm/footerForm";
import CommonInput from "../../adminCommons/commonInput/commonInput";
import "./schoolForm.css";

const SchoolFormModal = ({ isOpen, setIsOpen }) => {
  const [formData, setFormData] = React.useState({
    name: "",
    CNPJ: "",
    address: "",
    email: "",
    password: "",
  });

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  const handleChange = (e) => {
  
  
    const { name, value } = e.target;
  
    // Atualiza o estado
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const formDataToSend = {
        ...formData,
        role: 'school',
      };

      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${baseUrl}/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataToSend),
      });

      if (response.ok) {
        alert('Escola criada com sucesso!');
        setIsOpen(false);
        setFormData({
          nome: "",
          CNPJ: "",
          endereco: "",
          email: "",
          senha: "",
        });
      } else {
        const errorResponse = await response.json();
        setErrors((prev) => ({
          ...prev,
          server: errorResponse.message || 'Erro ao criar a escola.',
        }));
      }
    } catch (error) {
      console.error('Erro ao enviar o formulário:', error);
      alert('Erro de conexão. Verifique sua rede.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      return false;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <HeaderForm
          title="Adicionar Escola"
          onClose={() => setIsOpen(false)}
        />
        <div className="circle-and-form">
          <div className="form-content">
            <form onSubmit={handleSubmit}>
              <CommonInput
                label="Nome"
                name="name"
                placeholder="Nome da escola"
                value={formData.name}
                onChange={handleChange}
              />
              <CommonInput
                label="CNPJ"
                name="CNPJ"
                placeholder="CNPJ da escola"
                value={formData.CNPJ}
                onChange={handleChange}
              />
              <CommonInput
                label="Endereço"
                name="address"
                placeholder="Endereço da escola"
                value={formData.address}
                onChange={handleChange}
              />
              <CommonInput
                label="Email"
                name="email"
                placeholder="Email da escola"
                value={formData.email}
                onChange={handleChange}
              />
              <CommonInput
                label="Senha"
                name="password"
                placeholder="Senha da escola"
                value={formData.password}
                onChange={handleChange}
              />
            </form>
          </div>
        </div>
        <FooterForm onCancel={handleCancel} onConfirm={handleSubmit} />
      </div>
    </div>
  );
};

export default SchoolFormModal;