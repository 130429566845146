import React from "react";
import './genericListSchool.css';
const List = ({ title, image, children }) => {
    return (
      <div className="list-item">
        <div className="item-img">
          
          
        </div>
        <div className="item-info">
          <div className="item-title">
            <p>{title}</p>
          </div>
          {children}
          <button></button>
        </div>
      </div>
    );
  };

  export default List;