import Header_admin from "../../adminComponents/headerAdmin/header_admin";
function relatorioPage() {
  return (
    <div>
      <Header_admin />
      <h1>Relatórios</h1>
    </div>
  );
}

export default relatorioPage;