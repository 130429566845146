import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './bookshelf.css';
import Header from '../../userComponents/header/header';
import BookShelfList from '../../userController/bookshelf-list/bookshelf-list';
import Footer from '../../userComponents/footer-component/footer';
import { CiSearch } from "react-icons/ci";

const BookShelf = () => {
  const [libraryData, setLibraryData] = useState({ listItems: [] });
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchHelper = async (url, options = {}) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        if (response.status === 401) {
          throw new Error("Sessão expirada. Faça login novamente.");
        } else {
          throw new Error("Erro ao buscar dados da biblioteca.");
        }
      }
      return await response.json();
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      setError(error.message);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserBooks = async () => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      setError("Token de acesso ausente. Faça login novamente.");
      setIsLoading(false);
      return;
    }

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const booksData = await fetchHelper(`${baseUrl}/books/get_user_books`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (booksData) {
      setLibraryData({ listItems: booksData });
    } else {
      setLibraryData({ listItems: [] });
    }
  };

  useEffect(() => {
    fetchUserBooks();
  }, []);

  const filteredItems = Array.isArray(libraryData.listItems)
    ? libraryData.listItems.filter((item) =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.author.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  return (
    <>
      <Header pageTitle="Minha Estante" />

      <div className="search-component-bookshelf">
        <input
          type="text"
          placeholder="Pesquise por título ou autor"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input-bookshelf"
        />
        <CiSearch
          style={{
            position: 'absolute',
            right: '50px'
          }}
        />
      </div>

      {isLoading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <div className="loading-circle"></div>
          <p>Carregando livros...</p>
        </div>
      ) : error ? (
        <div style={{ textAlign: 'center', color: 'red' }}>
          <p>{error}</p>
        </div>
      ) : (
        filteredItems.length > 0 ? (
          filteredItems.map((item) => (
            <BookShelfList
              key={item.id}
              id={item.id}
              title={item.title}
              author={item.author}
              totalPages={item.total_pages}
              pagesRead={item.page_number}
            />
          ))
        ) : (
          <p style={{ textAlign: 'center' }}>Nenhum livro encontrado.</p>
        )
      )}

      <Footer />
    </>
  );
};

export default BookShelf;