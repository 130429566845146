import AjudaPage from "../adminPages/ajudaPage/ajuda.page";
import React from 'react'

function Ajuda() {
    return (
      <div>
        <AjudaPage/>
      </div>
    );
}

export default Ajuda;