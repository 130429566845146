import React from 'react';
import ConfirmButton from '../../adminCommons/confirmButton/confirmButton';
import CancelButton from '../../adminCommons/cancelButton/cancelButton';
import './footerForm.css'

const FooterForm = ({ onCancel, onConfirm , ConfirmButtonType = "button" }) => (
  <div className='footer-form'>
    <CancelButton onClick={onCancel} />
    <ConfirmButton onClick={onConfirm} type={ConfirmButtonType} />
    
  </div>
);

export default FooterForm;