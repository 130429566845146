import React, { useState, useEffect } from 'react';
import './library.css';
import Header from '../../userComponents/header/header';
import LibraryList from '../../userComponents/library-list/library-list';
import Footer from '../../userComponents/footer-component/footer';
import { CiSearch } from "react-icons/ci";

const Library = () => {
  const [libraryData, setLibraryData] = useState({ listItems: [] });
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null); // Estado para gerenciar erros

  const fetchUserIdAndData = async () => {
    setIsLoading(true); // Inicia o carregamento
    setError(null); // Reseta erros anteriores

    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error("Token de acesso ausente. Faça login novamente.");
      }

      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      console.log('Token:', token);
      const response = await fetch(`${baseUrl}/books/get_library_books`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error("Sessão expirada. Faça login novamente.");
        } else {
          throw new Error("Erro ao buscar dados da biblioteca.");
        }
      }

      const booksData = await response.json();
      setLibraryData({ listItems: booksData });
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      setError(error.message); // Salva a mensagem de erro
      setLibraryData({ listItems: [] }); // Limpa os dados em caso de erro
    } finally {
      setIsLoading(false); // Finaliza o carregamento
    }
  };

  useEffect(() => {
    fetchUserIdAndData();
  }, []);

  // Função para adicionar livro à biblioteca do usuário
  const addToUserLibrary = async (bookId) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const url = `${baseUrl}/add_book_to_user/${bookId}`;
    const token = localStorage.getItem('access_token');

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        console.log("Livro adicionado à biblioteca do usuário com sucesso!");
        fetchUserIdAndData(); // Atualiza os dados após a adição
      } else {
        console.error("Erro ao adicionar o livro à biblioteca.");
      }
    } catch (error) {
      console.error("Erro ao conectar com o servidor", error);
    }
  };

  // Filtro de pesquisa para livros
  const filteredItems = libraryData.listItems.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.author.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Header pageTitle="Biblioteca" />

      <div className="search-component-library">
        <input
          type="text"
          placeholder="Pesquise por título ou autor"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input-library"
        />
        <CiSearch
          style={{
            position: 'absolute',
            right: '50px'
          }}
        />
      </div>

      {isLoading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <div className="loading-circle"></div>
          <p>Carregando livros...</p>
        </div>
      ) : error ? (
        <div style={{ textAlign: 'center', color: 'red' }}>
          <p>{error}</p>
        </div>
      ) : (
        filteredItems.length > 0 ? (
          filteredItems.map((item) => (
            <LibraryList
              key={item.id}
              id={item.id}
              title={item.title}
              author={item.author}
              image={item.cover}
              totalPages={item.totalPages}
              onAddBook={addToUserLibrary}
            />
          ))
        ) : (
          <p style={{ textAlign: 'center' }}>Nenhum livro encontrado.</p>
        )
      )}

      <Footer />
    </>
  );
};

export default Library;