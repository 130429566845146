import React from "react";
import "./schoolCard.css";

const SchoolCard = ({ name }) => {
  return (
    <div className="school-card">
      <div className="school-book-card-info">
        <h1 className="school-card-name">{name}</h1>
  
        </div>
      </div>
  
  );
};

export default SchoolCard;