import React from "react";


const CatalogSinopsis = ( { isOpen, onClose, bookData }) =>{

    if(!isOpen) return null;


    return(
        <div className="synopsis-container">

            <div className="book-title">
                <h1>{bookData.title}</h1>
            </div>

            <div className="page-info">
                <span>{bookData.page_number} páginas lidas</span>
                  <span className="pages-left">
                 faltam {bookData.total_pages - bookData.page_number} páginas
                 </span>
            </div>

            <div className="book-description">
                <p>{bookData.description}</p>
            </div>

            <div className="book-info">
                    <p>
                    <strong>Autor:</strong> {bookData.author}
                    </p>
                    <p>
                    <strong>Gênero:</strong> {bookData.category_name}
                    </p>
                    <p>
                    <strong>Capítulo:</strong> {bookData.chapter_number}
                    </p>
                    <p>
                    <strong>Publicado em:</strong> {new Date(bookData.published_at).toLocaleDateString()}
                    </p>
                    <p>
                    <strong>Total de Páginas:</strong> {bookData.total_pages}
                    </p>
            </div>




        </div>
    );
};

export default CatalogSinopsis;