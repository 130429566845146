import React from 'react';
import "./studentList.css"

const StudentList = ({ students }) => {
  return (
    <table className="student-table">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Email</th>
          <th>Turma</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        {students.map((student) => (
          <tr key={student.id} className="student-row">
            <td>{student.nome}</td>
            <td>{student.email}</td>
            <td>{student.turma}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default StudentList;
