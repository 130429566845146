import React from "react";


const ErrorPage = () => {
    return (
        <div>

            <p>Erro 404</p>
        </div>
    );
};


export default ErrorPage;